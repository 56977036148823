import { Link } from 'gatsby';
import React, { useState } from 'react';
import { Button, Modal, Table } from 'semantic-ui-react';
import styled from 'styled-components';

import burialSocietyFlyer from '../../assets/images/burial-society-flyer.png';
import member1 from '../../assets/images/member_1.jpg';
import member11 from '../../assets/images/member_11.jpg';
import member2 from '../../assets/images/member_2.jpg';
import member3 from '../../assets/images/member_3.jpg';
import member6 from '../../assets/images/member_6.jpg';
import member8 from '../../assets/images/member_8.jpg';
import Header from '../../components/Header';
import Layout from '../../components/layout';
import MemberTile from '../../components/MemberTile';
import burialSocietyLogo from '../../assets/images/amafu.png';

const AboutAmafu = ({ className }) => {
  const [open, setOpen] = useState(false);

  return (
    <Layout title="About Amafu" className={className} >
      <Header title="About Amafu" />
      <div id="main">
        <section id="content" className="main">
          <img style={{ maxWidth: '100%', maxHeight: '100%' }} src={burialSocietyLogo} alt="burial-society-details" />
          <header className="major">
            <h2>How it works</h2>
          </header>
          <p>
            A burial society is the initial step in establishing the Amangwe empire, where each member will make a monthly
            contribution of R 200. This R 200 will be dived into the following four categories:
          </p>
          <ul>
            <li><b>R 100</b> Burial society: Everyone who joins this society can submit up to 16* people that should be covered by this burial scheme.</li>
            <li><b>R 50</b> Amangwe empire savings: Each R50 out of all R200 paid monthly will be saved as project establishments funds. So any business idea that has been presented and approved can be established using these savings, the person who came with the idea will co-own the business with the Amangwe holding company.</li>
            <li><b>R 30</b> Operational fund: As we start this empire we need to fund the operational costs of the business. This includes renting offices, paying of staff and other expenses such as marketing, fuel and so on.</li>
            <li><b>R 20</b> Compensation fee: We are encouraging everyone who is involved in this business to tell other people, with each person you gain R20 everytime they pay R200. If you have recruited 100 people for example you can claim R2000 monthly, we will start by recruiting Amangwe people only, but after reaching 400 members we can start recruiting anyone, this is to ensure that the founders are people from Emangweni.</li>
          </ul>
          <p>
            The funeral benefit is payable on the death of an Insured person. The selected benefit is paid in a lump sum.  Maximum entry age 65 on the next birthday. There is a six (6) month waiting period, except in the event of an accident.
          </p>
          <Table celled size="small">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Beneficiary</Table.HeaderCell>
                <Table.HeaderCell>Cover Amount</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              <Table.Row>
                <Table.Cell>Principal Insured / Spouse</Table.Cell>
                <Table.Cell>R 10 000</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Still born to 11 months</Table.Cell>
                <Table.Cell>R 1 250</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>1 to 5 years</Table.Cell>
                <Table.Cell>R 2 500</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>6 to 13 years</Table.Cell>
                <Table.Cell>R 5 000</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>14 to 21 years</Table.Cell>
                <Table.Cell>R 8 000</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>22 to 64 years</Table.Cell>
                <Table.Cell>R 8 000</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
          <Modal
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            trigger={<Button>More about the burial society</Button>}
          >
            <Modal.Header>Amafu Burial Society</Modal.Header>
            <Modal.Content image color="black">
              <Modal.Description>
                <img style={{ maxWidth: '100%', maxHeight: '100%' }} src={burialSocietyFlyer} alt="burial-society-details" />
              </Modal.Description>
            </Modal.Content>
          </Modal>
        </section>
        <section id="content" className="main">
          <header className="major">
            <h2>Meet the Committee</h2>
          </header>
          <ul className="members">
            <MemberTile
              name="Themba Zwane"
              location="Lombardy, JHB"
              image={member1}
            />
            <MemberTile
              name="Mduduzi Zwane"
              location="Paulpietersburg, KZN"
              image={member2}
            />
            <MemberTile
              name="Gugu Ngcobo"
              location="Thembisa, GP"
              image={member3}
            />
            <MemberTile
              name="Zanele Zwane"
              location="Dawn Park, GP"
              image={member11}
            />
            <MemberTile
              name="Mbali Zwane"
              location="Soweto, GP"
              image={member6}
            />
            <MemberTile
              name="Phindile Zwane"
              location="Nelspruit, MP"
              image={member8}
            />
          </ul>
          <Link to="/about/members">View member breakdown</Link>
        </section>

        <section id="cta" className="main special">
          <header className="major">
            <h2>Get started by signing up</h2>
            <p>
              Fill out the online form to register to be a part of the Amangwe movement.
            </p>
          </header>
          <footer className="major">
            <ul className="actions">
              <li>
                <Link to="/register/amafu" className="button special">
                  Sign up
                </Link>
              </li>
            </ul>
          </footer>
        </section>
      </div>
    </Layout >
  )
}

export default styled(AboutAmafu)`
  & header {
    text-align: center;
    &.major h2:after {
      margin: auto;
    }
    @media screen and (max-width: 736px) {
      &.major {
        margin-bottom: 2em;
      }
    }
  }

  & section {
    text-align: center;
  }

  & .members {
    margin-top: 10px;

    li {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
`;
