import React from 'react';
import defaultImage from '../assets/images/member_x.png';

const MemberTile = ({ name, location, image }) => (
  <li>
    <img src={image || defaultImage} alt={`${name}`} />
    <h3>{name}</h3>
    <p>{location}</p>
  </li>
);

export default MemberTile;
